// Engaged Tv ADM
export default {
  info: {
    NAME: 'dila-app',
  },
  api: {
    BASE_URL: 'api.dilatvpro.com',
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'pvqa8ka8a9', // app-auth
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_e65rJMnBx",
    APP_CLIENT_ID: "7kifh9s123ijfevqg4gg0t52sq",
    IDENTITY_POOL_ID: "us-east-1:573825a4-1139-4abb-82be-de6a0ef661f3"
  },
  pinpoint: {
    APP_ID: "558eded560eb4bd4b823cc8f72175c4d",
    REGION: "us-east-1",
  },
  // --------------------------------------------------------------------------------- S3 AUTH -----
  S3_THUMBNAILS_PROFILE: {
    REGION: "us-east-1",
    BUCKET: "dila-thumbnails-profile",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_INFLUENCER: {
    REGION: "us-east-1",
    BUCKET: "dila-thumbnails-influencer",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  // ------------------------------------------------------------------------------- S3 MOVIES -----
  S3_THUMBNAILS_LIVE: {
    REGION: "us-east-1",
    BUCKET: "dila-thumbnails-live",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_CATALOG: {
    REGION: "us-east-1",
    BUCKET: "dila-thumbnails-catalog",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_MEDIA: {
    REGION: "us-east-1",
    BUCKET: "dila-thumbnails-media",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  // --------------------------------------------------------------------------------- S3 FILM -----
  S3_MEDIA_FILM: {
    REGION: "us-east-1",
    BUCKET: "dila-vod-movies-source-34jyqi3n6ube",
    MAX_ATTACHMENT_SIZE: 25000000000, //25 GB
  },
  // --------------------------------------------------------------------------------- S3 SONG -----
  S3_THUMBNAILS_CATEGORY: {
    REGION: "us-east-1",
    BUCKET: "dila-thumbnails-category",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_LABEL: {
    REGION: "us-east-1",
    BUCKET: "dila-thumbnails-label",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_ARTIST: {
    REGION: "us-east-1",
    BUCKET: "dila-thumbnails-artist",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_ALBUM: {
    REGION: "us-east-1",
    BUCKET: "dila-thumbnails-album",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_SONG: {
    REGION: "us-east-1",
    BUCKET: "dila-thumbnails-song",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
};
