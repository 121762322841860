import React from 'react';
import { Auth } from 'aws-amplify';
import { subDays } from 'date-fns';
import api from '../../services/api';

// import { Container } from './styles';

const Test = () => {
  async function authSignUp(event) {
    try {
      console.log('Sign Up');
      const authNew = await Auth.signUp({
        username: 'kes@dev.com',
        password: 'Kesley#2019',
        attributes: {
          'custom:useName': 'useName test',
          'custom:useNameLast': 'useNameLast test',
          'custom:useCodeInfluencer': '001',
        },
      });
      console.log(authNew);
      console.log('Register -> Ok');
    } catch (error) {
      console.error(error);
      console.log('Register => Error');
    }
  }

  async function authSignIn(event) {
    console.log('Sign In');
    event.preventDefault();

    try {
      const login = await Auth.signIn('kes@dev.com', 'Kesley#2019');
      console.log('Logged in');
      console.log(login);
    } catch (e) {
      console.error(e.message);
    }
  }

  async function authIsAuth(event) {
    console.log('Is Auth');
    try {
      await Auth.currentSession();
      // console.log(teste)
      console.log(true);
    } catch (e) {
      console.log(false);
      console.error(e);
    }
  }

  async function authLoadUser(event) {
    console.log('Load User');
    try {
      const teste = await Auth.currentSession();
      console.log(teste);
      const keys = await Auth.currentCredentials();
      console.log(keys);
    } catch (e) {
      console.log(false);
      console.error(e);
    }
  }

  async function authSignOut(event) {
    console.log('Sign Out');
    Auth.signOut();
  }

  async function mediaGet(event) {
    const resPutData = await api('GET', `/media/2dc27d3b-d107-4794-b9b5-08d946bcba6c`, {});
    console.log(resPutData);
  }

  async function mediaGetLink(event) {
    const resPutData = await api('GET', `/media/2dc27d3b-d107-4794-b9b5-08d946bcba6c/link/link-trailer`, {});
    console.log(resPutData);
  }

  async function mediaPutLink(event) {
    const resPutData = await api('PUT', `/media/2dc27d3b-d107-4794-b9b5-08d946bcba6c/link/link-trailer/info`, {});
    console.log(resPutData);
  }

  async function reportGenerate(event) {
    console.log('reportGenerate => start');

    const lstPromises = [];

    let dateReport = new Date();

    for (let i = 0; i < 50; i++) {
      dateReport = subDays(dateReport, 1);
      console.log(`${i} => ${dateReport.toDateString()}`);
      lstPromises.push(
        api('POST', '/dash/generate', {
          repDate: dateReport,
        })
      );
    }
    const resGetData1 = await Promise.all(lstPromises);
    console.log(resGetData1);

    for (let i = 0; i < 50; i++) {
      dateReport = subDays(dateReport, 1);
      console.log(`${i} => ${dateReport.toDateString()}`);
      lstPromises.push(
        api('POST', '/dash/generate', {
          repDate: dateReport,
        })
      );
    }
    const resGetData2 = await Promise.all(lstPromises);
    console.log(resGetData2);

    for (let i = 0; i < 50; i++) {
      dateReport = subDays(dateReport, 1);
      console.log(`${i} => ${dateReport.toDateString()}`);
      lstPromises.push(
        api('POST', '/dash/generate', {
          repDate: dateReport,
        })
      );
    }
    const resGetData3 = await Promise.all(lstPromises);
    console.log(resGetData3);

    for (let i = 0; i < 50; i++) {
      dateReport = subDays(dateReport, 1);
      console.log(`${i} => ${dateReport.toDateString()}`);
      lstPromises.push(
        api('POST', '/dash/generate', {
          repDate: dateReport,
        })
      );
    }
    const resGetData4 = await Promise.all(lstPromises);
    console.log(resGetData4);

    for (let i = 0; i < 50; i++) {
      dateReport = subDays(dateReport, 1);
      console.log(`${i} => ${dateReport.toDateString()}`);
      lstPromises.push(
        api('POST', '/dash/generate', {
          repDate: dateReport,
        })
      );
    }
    const resGetData5 = await Promise.all(lstPromises);
    console.log(resGetData5);

    for (let i = 0; i < 50; i++) {
      dateReport = subDays(dateReport, 1);
      console.log(`${i} => ${dateReport.toDateString()}`);
      lstPromises.push(
        api('POST', '/dash/generate', {
          repDate: dateReport,
        })
      );
    }
    const resGetData6 = await Promise.all(lstPromises);
    console.log(resGetData6);

    for (let i = 0; i < 50; i++) {
      dateReport = subDays(dateReport, 1);
      console.log(`${i} => ${dateReport.toDateString()}`);
      lstPromises.push(
        api('POST', '/dash/generate', {
          repDate: dateReport,
        })
      );
    }
    const resGetData7 = await Promise.all(lstPromises);
    console.log(resGetData7);

    for (let i = 0; i < 50; i++) {
      dateReport = subDays(dateReport, 1);
      console.log(`${i} => ${dateReport.toDateString()}`);
      lstPromises.push(
        api('POST', '/dash/generate', {
          repDate: dateReport,
        })
      );
    }
    const resGetData8 = await Promise.all(lstPromises);
    console.log(resGetData8);

    for (let i = 0; i < 50; i++) {
      dateReport = subDays(dateReport, 1);
      console.log(`${i} => ${dateReport.toDateString()}`);
      lstPromises.push(
        api('POST', '/dash/generate', {
          repDate: dateReport,
        })
      );
    }
    const resGetData9 = await Promise.all(lstPromises);
    console.log(resGetData9);

    for (let i = 0; i < 50; i++) {
      dateReport = subDays(dateReport, 1);
      console.log(`${i} => ${dateReport.toDateString()}`);
      lstPromises.push(
        api('POST', '/dash/generate', {
          repDate: dateReport,
        })
      );
    }
    const resGetData10 = await Promise.all(lstPromises);
    console.log(resGetData10);

    for (let i = 0; i < 50; i++) {
      dateReport = subDays(dateReport, 1);
      console.log(`${i} => ${dateReport.toDateString()}`);
      lstPromises.push(
        api('POST', '/dash/generate', {
          repDate: dateReport,
        })
      );
    }
    const resGetData11 = await Promise.all(lstPromises);
    console.log(resGetData11);

    for (let i = 0; i < 50; i++) {
      dateReport = subDays(dateReport, 1);
      console.log(`${i} => ${dateReport.toDateString()}`);
      lstPromises.push(
        api('POST', '/dash/generate', {
          repDate: dateReport,
        })
      );
    }
    const resGetData12 = await Promise.all(lstPromises);
    console.log(resGetData12);

    for (let i = 0; i < 50; i++) {
      dateReport = subDays(dateReport, 1);
      console.log(`${i} => ${dateReport.toDateString()}`);
      lstPromises.push(
        api('POST', '/dash/generate', {
          repDate: dateReport,
        })
      );
    }
    const resGetData13 = await Promise.all(lstPromises);
    console.log(resGetData13);

    for (let i = 0; i < 50; i++) {
      dateReport = subDays(dateReport, 1);
      console.log(`${i} => ${dateReport.toDateString()}`);
      lstPromises.push(
        api('POST', '/dash/generate', {
          repDate: dateReport,
        })
      );
    }
    const resGetData14 = await Promise.all(lstPromises);
    console.log(resGetData14);

    console.log('reportGenerate => end');
  }

  return (
    <>
      <h1>TEST Page</h1>
      <br />
      <button onClick={authSignUp}>SignUp</button>
      <button onClick={authSignIn}>SignIn</button>
      <button onClick={authIsAuth}>IsAuth</button>
      <button onClick={authLoadUser}>LoadUser</button>
      <button onClick={authSignOut}>SignOut</button>
      <br />
      <br />
      <h1>DASH Report</h1>
      <br />
      <button onClick={reportGenerate}>Report Generate</button>
      <br />
      <br />
      <button onClick={mediaGet}>Get Media</button>
      <button onClick={mediaGetLink}>Get Media Link</button>
      <button onClick={mediaPutLink}>Put Media Link</button>
    </>
  );
};

export default Test;
