import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  NotificationContainer,
  NotificationManager as N,
} from 'react-notifications';

import { useHistory } from 'react-router-dom';

import api from '../../../../services/api';
import UserManagerContext from '../../UserManagerContext';

import ButtonLoader from '../../../../components/ButtonLoader';
import RatingView from '../../../../components/RatingView';

import 'react-notifications/lib/notifications.css';
import * as S from './styles';

const Info = ({ propData, propNewData }) => {
  console.log(propData);
  const history = useHistory();
  const { viewLoading, viewError, viewContent } = useContext(
    UserManagerContext
  );

  const [objData, setData] = useState(propData);
  // console.log(objData);

  const optStatus = [
    { id: 'Active', title: 'Active' },
    { id: 'Processing', title: 'Processing' },
    { id: 'Disabled', title: 'Disabled' },
    { id: 'Blocked', title: 'Blocked' },
  ];
  const optStatusAuth = [
    { id: 'Authenticated', title: 'Authenticated' },
    { id: 'Unauthenticated', title: 'Unauthenticated' },
  ];

  useEffect(() => {
    async function loadData() {
      viewContent();
    }
    try {
      loadData();
    } catch (error) {
      viewError();
    }
  }, []);

  const handleSubmit = async data => {
    N.warning('Coming soon');
    // try {
    //   if (handleValidate()) {
    //     if (propNewData) {
    //       viewLoading();
    //       console.log(`Data POST`, data);
    //       const resPostData = await api('POST', `/user`, data);
    //       console.log(resPostData);

    //       N.success('Successfully created');

    //       setTimeout(() => {
    //         history.push(`/user/${resPostData.data.resData.infId}`);
    //         window.location.reload();
    //       }, 1500);
    //     } else {
    //       viewLoading();
    //       console.log(`Data PUT`, data);
    //       const resPutData = await api(
    //         'PUT',
    //         `/user/${objData.infId}/info`,
    //         data
    //       );
    //       console.log(resPutData);

    //       N.success('Successfully updated');

    //       setTimeout(() => {
    //         // history.push('/');
    //         window.location.reload();
    //       }, 1500);
    //     }
    //   }

    //   function handleValidate() {
    //     let varControl = true;

    //     console.log(data);

    //     if (data.infId.length !== 3) {
    //       varControl = false;
    //       N.error('Code', 'Invalid format');
    //     }

    //     if (data._infStatus !== 'Active' && data._infStatus !== 'Disabled') {
    //       varControl = false;
    //       N.error('Status', 'Check parameters');
    //     } else {
    //       switch (data._infStatus) {
    //         case 'Active':
    //           data.infStatus = 0;
    //           break;
    //         case 'Disabled':
    //           data.infStatus = 2;
    //           break;
    //         default:
    //           varControl = false;
    //           N.error('Error Status', 'Check parameters');
    //           break;
    //       }
    //     }

    //     if (data._infPermission !== 'Active' && data._infPermission !== 'Disabled') {
    //       varControl = false;
    //       N.error('Permission', 'Check parameters');
    //     } else {
    //       switch (data._infPermission) {
    //         case 'Active':
    //           data.infPermission = 0;
    //           break;
    //         case 'Disabled':
    //           data.infPermission = 2;
    //           break;
    //         default:
    //           varControl = false;
    //           N.error('Error Permission', 'Check parameters');
    //           break;
    //       }
    //     }

    //     if (data.infEmail === '') {
    //       varControl = false;
    //       N.error('Email', 'Check parameters');
    //     }

    //     if (data.infName === '') {
    //       varControl = false;
    //       N.error('Name', 'Check parameters');
    //     }

    //     if (data.infAbout === '') {
    //       varControl = false;
    //       N.error('About', 'Check parameters');
    //     }

    //     return varControl;
    //   }
    // } catch (error) {
    //   console.error(error);
    //   if (error.response.status === 409) {
    //     N.error(error.response.data.resMessage);
    //     viewContent();
    //   } else {
    //     N.error('Error save');
    //     viewContent();
    //   }
    // }
  };

  return (
    <>
      <S.Form onSubmit={handleSubmit} initialData={objData}>
        <S.DivInput propW="20%">
          <small>Status</small>
          <S.Select name="_useStatus" options={optStatus} disabled={false} />
        </S.DivInput>
        <S.DivInput propW="20%">
          <small>Permission</small>
          <S.Input
            name="_usePermission"
            type="text"
            disabled={true}
          />
        </S.DivInput>
        <S.DivInput propW="10%">
          <small>Device</small>
          <S.Input
            name="PAYMENT.payDevice"
            type="text"
            disabled={true}
          />
        </S.DivInput>
        <S.DivInput propW="25%">
          <small>Date Created</small>
          <S.Input name="_useDateCreated" disabled />
        </S.DivInput>
        <S.DivInput propW="25%">
          <small>Date Updated</small>
          <S.Input name="_useDateUpdated" disabled />
        </S.DivInput>
        <S.DivInput propW="34%">
          <small>Email</small>
          <S.Input name="useEmail" type="email" disabled={true} />
        </S.DivInput>
        <S.DivInput propW="33%">
          <small>
            Influencer ID
            {objData.useInfluencer === 0 && <S.Link to={`/influencer/${objData.INFLUENCER.infId}`}>[ view ]</S.Link>}
          </small>
          <S.Input name="INFLUENCER.infId" disabled />
        </S.DivInput>
        <S.DivInput propW="33%">
          <small>
            Catalog ID
            {objData.useCatalog === 0 && <S.Link to={`/catalog/${objData.CATALOG.catId}`}>[ view ]</S.Link>}
            {/* {objData.useCatalog === 1 && <S.Link to={`/catalog/new?code=${objData.useId}`}>[ CREATE ]</S.Link>} */}
            {/* kes-dev-prod */}
          </small>
          <S.Input name="CATALOG.catName" disabled />
        </S.DivInput>
        <S.DivInput propW="34%">
          <small>Nickname</small>
          <S.Input name="useNickname" disabled={false} />
        </S.DivInput>
        <S.DivInput propW="33%">
          <small>Name</small>
          <S.Input name="useName" disabled={false} />
        </S.DivInput>
        <S.DivInput propW="33%">
          <small>Name Last</small>
          <S.Input name="useNameLast" disabled={false} />
        </S.DivInput>
        <S.DivInput propW="100%">
          <small>About</small>
          <S.Input name="useAbout" disabled={false} />
        </S.DivInput>
        <S.DivButton>
          <ButtonLoader propTitle="SAVE" propSpinner={false} />
        </S.DivButton>
      </S.Form>
      <NotificationContainer />
    </>
  );
};

Info.defaultProps = {
  propData: {},
  propNewData: false,
};

Info.propTypes = {
  propData: PropTypes.object,
  propNewData: PropTypes.bool,
};

export default Info;
