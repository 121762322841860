import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';
import { NotificationContainer } from 'react-notifications';
import api from '../../services/api';
import UserManagerContext from './UserManagerContext';

import NavBar from '../../components/NavBar';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import Line from '../../components/Line';
import NavTabsGeneral from '../../components/NavTabsGeneral';

import TabInfo from './Tabs/Info';
import TabImage from './Tabs/Image';
import TabPlan from './Tabs/Plan';

import * as S from './styles';

const UserManager = props => {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [isContent, setContent] = useState(false);
  const [isNewData, setNewData] = useState(false);
  const [objData, setData] = useState({});

  const auxFormatData = data => {
    data.id = data.useId;
    data.useCatalog = 1; //kes-dev-tmp
    data.CATALOG = {}; //kes-dev-tmp
    switch (data.useStatus) {
      case 0:
        data._useStatus = 'Active';
        break;
      case 1:
        data._useStatus = 'Processing';
        break;
      case 2:
        data._useStatus = 'Disabled';
        break;
      case 3:
        data._useStatus = 'Blocked';
        break;
      default:
        data._useStatus = 'Blocked';
        break;
    }
    switch (data.usePermission) {
      case 0:
        data._usePermission = 'User';
        break;
      case 1:
        data._usePermission = 'Plus';
        break;
      case 2:
        data._usePermission = 'Moderator';
        break;
      case 3:
        data._usePermission = 'Admin';
        break;
      default:
        data._usePermission = '[error]';
        break;
    }
    data._useDateCreated = format(new Date(data.useDateCreated), 'MM/dd/yyyy');
    data._useDateUpdated = format(new Date(data.useDateUpdated), 'MM/dd/yyyy');
    // PAYMENT
    switch (data.PAYMENT.payStatus) {
      case 0:
        data.PAYMENT._payStatus = 'Active';
        break;
      case 1:
        data.PAYMENT._payStatus = 'Processing';
        break;
      case 1:
        data.PAYMENT._payStatus = 'Error';
        break;
      default:
        data.PAYMENT._payStatus = 'Error';
        break;
    }
    data.PAYMENT._payRecurringBilling = data.PAYMENT.payRecurringBilling === 0 ? 'true' : 'false';
    data.PAYMENT._payRecurringPeriodEnd = format(new Date(data.PAYMENT.payRecurringPeriodEnd), 'MM/dd/yyyy');
    data.PAYMENT._payDateCreated = format(new Date(data.PAYMENT.payDateCreated), 'MM/dd/yyyy');
    data.PAYMENT._payDateUpdated = format(new Date(data.PAYMENT.payDateUpdated), 'MM/dd/yyyy');
    return data;
  };

  useEffect(() => {
    async function loadData() {
      const {
        match: {
          params: { id },
        },
      } = props;

      if (id === 'new') {
        // New Data
        setNewData(true);
        viewContent();
      } else {
        const apiGetData = api('GET', `/user/${id}`, null);

        const resGetData = await apiGetData;
        const {
          data: { resData },
        } = resGetData;
        // console.log(resData);
        setData(auxFormatData(resData));
        viewContent();
      }
    }
    try {
      loadData();
    } catch (error) {
      viewError();
    }
  }, []);

  const viewData = () => {
    return (
      <S.DivData>
        <NavTabsGeneral
          propContext={objData}
          propTabs={[
            {
              title: 'Info',
              component: <TabInfo propData={objData} propNewData={isNewData} />,
            },
            !isNewData && {
              title: 'Images',
              component: <TabImage propData={objData} />,
            },
            !isNewData && {
              title: 'Plan',
              component: <TabPlan propData={objData} />,
            },
          ]}
        />
      </S.DivData>
    );
  };

  const viewLoading = status => {
    if (status === undefined) {
      console.log('type A');
      setContent(false);
      setError(false);
      setLoading(true);
    } else {
      console.log('type B');
      setLoading(status);
    }
  };

  const viewError = () => {
    setContent(false);
    setLoading(false);
    setError(true);
  };

  const viewContent = () => {
    setLoading(false);
    setError(false);
    setContent(true);
  };

  return (
    <>
      <NavBar />
      <S.Container>
        <S.ContainerHeader>
          <S.Title>User</S.Title>
          <Line />
        </S.ContainerHeader>
        <S.ContainerContent>
          <UserManagerContext.Provider
            value={{ viewLoading, viewError, viewContent }}
          >
            {isLoading && <Loader />}
            {isError && <Error />}
            {isContent && viewData()}
            {/* {isLoading ? <Loader /> : viewData()} */}
          </UserManagerContext.Provider>
        </S.ContainerContent>
      </S.Container>
      <NotificationContainer />
    </>
  );
};

export default UserManager;
